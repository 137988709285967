export function getLoanPurposeType(purposeId, gseRefinancePurposeType = "") {
  const cashOutRefiSubTypes = [
    "CashOutDebtConsolidation",
    "CashOutHomeImprovement",
    "CashOutLimited",
    "CashOutOther",
  ];

  const noCashOutRefiSubTypes = [
    "NoCashOutFHAStreamlinedRefinance",
    "NoCashOutFREOwnedRefinance",
    "NoCashOutOther",
    "NoCashOutStreamlinedRefinance",
    "ChangeInRateTerm",
  ];

  let purposeType = null;

  switch (purposeId) {
    case 1:
      purposeType = "Purchase";
      break;
    case 2:
    case 3:
      if (cashOutRefiSubTypes.includes(gseRefinancePurposeType)) {
        purposeType = "Cash-Out Refinance";
      } else if (noCashOutRefiSubTypes.includes(gseRefinancePurposeType)) {
        purposeType = "NoCash-Out Refinance";
      }
      break;
    case 4:
      purposeType = "ConstructionOnly";
      break;
    case 5:
      purposeType = "ConstructionToPermanent";
      break;
    case 6:
      purposeType = "Other";
      break;
    default:
      purposeType = null;
      break;
  }

  return purposeType;
}
export const getPropertyUsageType = (propertyUsageId) => {
  const propertyUsageType = {
    1: "PrimaryResidence",
    2: "SecondHome",
    3: "InvestmentProperty",
  };
  return propertyUsageType[propertyUsageId] ?? null;
};

export function parseNumber(x, defaultValue) {
  if (typeof x === "number" && !Number.isNaN(x)) {
    return x;
  }
  if (typeof x === "string") {
    const parsedString = x.replace(/[^0-9.-]/gi, "");
    const parsedNumber =
      parsedString.length === 0 ? defaultValue : Number(parsedString);
    return Number.isNaN(parsedNumber) ? defaultValue : parsedNumber;
  }
  return defaultValue;
}

export const convertLoanInfoToEPCModel = (loanInfo) => {
  const ltv = loanInfo.loanInformation.ltv
    ? Number(loanInfo.loanInformation.ltv)
    : null;
  const loanPurposeType = getLoanPurposeType(
    loanInfo.loanInformation.loanPurpose
  );
  const propertyUsage = getPropertyUsageType(loanInfo.property.use);

  const monthlyPropertyTaxes = parseNumber(loanInfo.property.tax / 12, 0);
  const monthlyInsuranceAmount = parseNumber(
    loanInfo.property.insuranceAmount / 12,
    0
  );
  const epcLoanInformation = {
    baseLoanAmount: loanInfo.loanInformation.firstMortgageAmount,
    property: {
      streetAddress: loanInfo.property.streetAddress,
      city: loanInfo.property.city,
      state: loanInfo.property.state,
      postalCode: loanInfo.property.zip,
      loanPurposeType,
      county: loanInfo.property.county,
      propertyUsageType: propertyUsage,
      loanPurposeTypeUrla: loanPurposeType,
    },
    applications: loanInfo.borrowers.map((borrower) => ({
      borrower: {
        firstNameWithMiddleName: borrower.firstName,
        lastNameWithSuffix: borrower.lastName,
        firstName: borrower.firstName,
        lastName: borrower.lastName,
      },
      propertyUsageType: propertyUsage,
    })),
    propertyAppraisedValueAmount: loanInfo.property.value,
    firstSubordinateLienAmount: loanInfo.loanInformation.firstMortgageAmount,
    subjectPropertyGrossRentalIncomeAmount: loanInfo.property.rentalIncome,
    proposedRealEstateTaxesAmount: monthlyPropertyTaxes.toFixed(2),
    proposedHazardInsuranceAmount: monthlyInsuranceAmount.toFixed(2),
    proposedDuesAmount: loanInfo.property.associationFee.toFixed(2),
    mortgageType: loanInfo.loanType,
    subjectPropertyOccupancyPercent: loanInfo.property.occupancyRate,
    borrowerRequestedLoanAmount: loanInfo.loanInformation.firstMortgageAmount,
    rateLock: {
      propertyUsageType: propertyUsage,
      mortgageType: loanInfo.loanType,
      baseLoanAmount: loanInfo.loanInformation.firstMortgageAmount,
      borrowerRequestedLoanAmount: loanInfo.loanInformation.firstMortgageAmount,
      purchasePriceAmount: loanInfo.property.value,
      propertyEstimatedValueAmount: loanInfo.property.value,
      helocCreditLimit: loanInfo.loanInformation.helocCreditLimit,
      subjectPropertyCity: loanInfo.property.city,
      subjectPropertyState: loanInfo.property.state,
      subjectPropertyPostalCode: loanInfo.property.zip,
      subjectPropertyStreetAddress: loanInfo.property.streetAddress,
      subjectPropertyCounty: loanInfo.property.county,
      propertyAppraisedValueAmount: loanInfo.property.value,
      subjectPropertyPropertyStreetAddress: loanInfo.property.streetAddress,
      subjectPropertyUnitNumber: loanInfo.property.unitNumber,
    },
    hmda: {
      msaNumber: loanInfo.property.msa,
      stateCode: loanInfo.property.stateCode,
      countyCode: loanInfo.property.countyCode,
    },
    creditScoreToUse: loanInfo?.borrowers[0]?.borrowerFinanancial?.creditScore,
    propertyEstimatedValueAmount: loanInfo.property.value,
    purchasePriceAmount: loanInfo.property.value,
    ltv,
  };
  return epcLoanInformation;
};
