import { getObject } from "@elliemae/em-ssf-guest";
import { runFetchJson, runFetch } from "utils/runFetch";
import createFetch from "utils/createFetch";
import { getApiUrl, getLoancatcherEnvTarget } from "utils/api";
import { pause } from "services/helpers";
import Session from "services/Session";
import Common from "./Common";

const createTransaction = async (transactionRequest) => {
  let result = null;
  try {
    const transactionObject = await getObject("transaction");
    const transactionData = await transactionObject.create(transactionRequest);
    result = transactionData.id;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  return result;
};

const getTransactionStatus = async (transactionId) => {
  const response = await runFetchJson(async () =>
    createFetch(
      `${getApiUrl()}/epc/transactions/${transactionId}`,
      {
        headers: {
          "X-EPC-Transaction-Uuid": await Common.getOriginId(),
          "X-Elli-PAT": await Common.getPATToken(),
          "X-PPE-Env": getLoancatcherEnvTarget(),
        },
      },
      {},
      {
        method: "GET",
      },
      [],
      true
    )
  );

  return response;
};

const updateTransaction = async (request) => {
  return runFetch(async () =>
    createFetch(
      `${getApiUrl()}/epc/transactions/${request.transactionID}`,
      {
        headers: {
          "X-EPC-Transaction-Uuid": await Common.getOriginId(),
          "X-Elli-PAT": await Common.getPATToken(),
          "X-PPE-Env": getLoancatcherEnvTarget(),
        },
        body: JSON.stringify(request),
      },
      {},
      { method: "PATCH" },
      [],
      true
    )
  );
};

const updatePollTransaction = async (request) => {
  // TODO: set default for all non-hosted environments to 'Webooks and Application Patch'
  // As soon as we fix the env file config thing
  const updateMode = Session.get("EPC_UPDATE_MODE") ?? "Webooks Only";

  if (updateMode === "Webhooks and Application Patch") {
    await updateTransaction(request);
  }

  const { transactionID } = request;

  try {
    const timeoutInSeconds = 30;
    for (let i = 0; i < timeoutInSeconds; i += 1) {
      /* eslint-disable no-await-in-loop */
      await pause(2000);

      const response = await getTransactionStatus(transactionID);
      if (response.summary === "Conflict") {
        throw new Error(`${response.summary}: ${response.details}`);
      }
      if (response.status === "completed") {
        return;
      }
    }

    throw new Error("Unable to verify transaction status");
  } catch (err) {
    throw new Error(err?.toString());
  }
};

export const epcService = {
  createTransaction,
  updatePollTransaction,
};
