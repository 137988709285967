import api from "utils/api";
import Common from "common/services/Common";
import Session, {
  ORIGIN_SOURCE,
  APPLICATION_NAME,
  ALL_SUMMARY_PARAMS,
  IS_LO,
  JWT_TOKEN,
  USER_TYPE,
  IS_BUYSIDE,
} from "services/Session";
import { updateBuySideAdjustmentsAction } from "data/origin/actionCreators";
import { lookupKeyFromValue, formatDate } from "services/helpers";
import {
  eppsToLNCTAdjustmentType,
  convertEPPSAdjustmentsToLNCT,
  createAdjustments,
} from "services/lockConfirmServices";

export const rateSelector = async (dispatch, loanData, buysideAdjustments) => {
  const {
    requestLockStatus,
    requestAction,
    loanInformation,
    relockRequest,
    eppsUserName,
  } = loanData || {};
  const { loanId: loanIdLoan, lienPosition } = loanInformation || {};
  const {
    specificRateDataID: rateDataIdLoan,
    specificProgramID: programIdLoan,
    effectiveDate: effectiveDateLoan,
  } = relockRequest || {};
  const isLOCmode = Session.get(IS_LO);
  const selectedProductData = Session.get(ALL_SUMMARY_PARAMS);

  const { loanId, rateDataId, programId, effectiveDate } =
    selectedProductData || {};
  const applicationName = isLOCmode
    ? Session.get(APPLICATION_NAME) || "ENCW"
    : "EPPS";

  const body = {
    loanId: loanId || loanIdLoan,
    sourceApplicationName: applicationName,
    sourceApplicationFormName: Session.get(ORIGIN_SOURCE) || "lockrequest",
    requestLockStatus,
    requestAction,
    eppsUserName,
    programId: programId || programIdLoan,
    rateDataID: rateDataId || rateDataIdLoan,
    lienPosition: lienPosition || 1,
    effectiveDate: [2, 4, 8].includes(requestAction)
      ? effectiveDate || effectiveDateLoan || ""
      : "",
  };

  let details = {};
  const user = Session.get(USER_TYPE);
  if (user && user.userType === "StandAlone") {
    details = await api.selectProgramRate(
      {
        body: JSON.stringify(body),
        customToken: { value: Session.get(JWT_TOKEN) },
        tokenType: "Bearer",
        loanId,
      },
      {}
    );

    return details;
  }

  // eslint-disable-next-line prefer-const
  let [eppsDetails, loancatcherAdjustments] = await Promise.all([
    await api.getWebhookData(
      {
        headers: { "X-Elli-PAT": await Common.getPATToken() },
        body: JSON.stringify(body),
      },
      await Common.getOriginId(),
      "LockRates"
    ),
    await getLoanCatcherAdjustments(loanInformation),
  ]);

  processLoancatcherAdjustments(
    dispatch,
    loanData,
    buysideAdjustments,
    loancatcherAdjustments
  );

  return eppsDetails;
};

export const processLoancatcherAdjustments = (
  dispatch,
  loanData,
  buysideAdjustments,
  loancatcherAdjustments
) => {
  const parsedEPPSAdjustmentGroups = loancatcherAdjustments.reduce(
    (sum, curr) => {
      const adjustmentType = lookupKeyFromValue(
        eppsToLNCTAdjustmentType,
        curr.adjustmentType
      );

      if (!adjustmentType) {
        return sum;
      }

      return {
        ...sum,
        [adjustmentType]: (sum[adjustmentType] ?? []).concat({
          ...curr,
          adjustmentType,
        }),
      };
    },
    {}
  );
  if (Session.get(IS_BUYSIDE)) {
    let mappedBuySideAdjustments = buysideAdjustments;

    if (Array.isArray(buysideAdjustments)) {
      const LockExtensionAdjustment = (buysideAdjustments ?? []).filter(
        (adj) =>
          adj.adjustmentType === "LockExtensionAdjustment" ||
          adj.adjustmentType === "LOCK_EXTENSION_ADJUSTMENT"
      );
      const ReLockFeeAdjustment = (buysideAdjustments ?? []).filter(
        (adj) =>
          adj.adjustmentType === "ReLockFeeAdjustment" ||
          adj.adjustmentType === "RE_LOCK_FEE_ADJUSTMENT"
      );
      const CustomPriceAdjustment = (buysideAdjustments ?? []).filter(
        (adj) =>
          adj.adjustmentType === "CustomPriceAdjustment" ||
          adj.adjustmentType === "CUSTOM_PRICE_ADJUSTMENT"
      );
      const CorporatePriceConcession = (buysideAdjustments ?? []).filter(
        (adj) =>
          adj.adjustmentType === "CorporatePriceConcession" ||
          adj.adjustmentType === "CORPORATE_PRICE_CONCESSION"
      );
      const BranchPriceConcession = (buysideAdjustments ?? []).filter(
        (adj) =>
          adj.adjustmentType === "BranchPriceConcession" ||
          adj.adjustmentType === "BRANCH_PRICE_CONCESSION"
      );

      mappedBuySideAdjustments = {
        LockExtensionAdjustment,
        ReLockFeeAdjustment,
        CustomPriceAdjustment,
        CorporatePriceConcession,
        BranchPriceConcession,
      };
    }

    // These are modeled incorrectly
    // Fixing it here as we are end of release cycle
    // This should be fixed permanently when we fully move to EPC models in Partner UI
    const moveToPrice = (adj) => {
      adj.price = adj.rate ?? adj.price;
      delete adj.rate;
      return adj;
    };
    parsedEPPSAdjustmentGroups.LockExtensionAdjustment = (
      mappedBuySideAdjustments?.LockExtensionAdjustment ?? []
    ).map((adj) => moveToPrice(adj));
    parsedEPPSAdjustmentGroups.ReLockFeeAdjustment = (
      mappedBuySideAdjustments?.ReLockFeeAdjustment ?? []
    ).map((adj) => moveToPrice(adj));
    parsedEPPSAdjustmentGroups.CorporatePriceConcession = (
      mappedBuySideAdjustments?.CorporatePriceConcession ?? []
    ).map((adj) => moveToPrice(adj));
    parsedEPPSAdjustmentGroups.BranchPriceConcession = (
      mappedBuySideAdjustments?.BranchPriceConcession ?? []
    ).map((adj) => moveToPrice(adj));
  }

  const parsedLoancatcherAdjustments = convertEPPSAdjustmentsToLNCT(
    Object.values(buysideAdjustments ?? {}).flat()
  );

  if (
    !Object.values(parsedEPPSAdjustmentGroups).length &&
    Object.values(parsedLoancatcherAdjustments).length !==
      Object.values(parsedEPPSAdjustmentGroups).length
  ) {
    createInitialLoanCatcherAdjustmentsForLoansInProgressFor243({
      loanData,
      parsedLoancatcherAdjustments,
      buysideAdjustments,
      dispatch,
    });
  } else {
    // eslint-disable-next-line no-restricted-syntax
    for (const [type, adjustments] of Object.entries(
      parsedEPPSAdjustmentGroups
    )) {
      dispatch(updateBuySideAdjustmentsAction(adjustments, type));
    }
  }
};

const createInitialLoanCatcherAdjustmentsForLoansInProgressFor243 = ({
  loanData,
  parsedLoancatcherAdjustments,
  buysideAdjustments,
  dispatch,
}) => {
  // Skip await here. This can send the adjustments in the background. We don't need to wait for it to finish.
  createAdjustments(loanData, parsedLoancatcherAdjustments);

  // eslint-disable-next-line no-restricted-syntax
  const flattenedBuysideAdjustments = Array.isArray(buysideAdjustments)
    ? buysideAdjustments
    : Object.values(buysideAdjustments ?? {}).flat(1);
  const initialBuysideAdjustments = flattenedBuysideAdjustments.reduce(
    (sum, _curr) => {
      const curr = { ..._curr };
      // eslint-disable-next-line prefer-destructuring
      const adjustmentType = curr.adjustmentType;
      if (!adjustmentType) {
        return sum;
      }

      curr.price = curr.rate;
      delete curr.rate;
      curr.status = "Submitted";

      if (curr.approvalDate) {
        curr.approvalDate = formatDate(new Date(curr.approvalDate), "Calendar");
      }

      return {
        ...sum,
        [adjustmentType]: (sum[adjustmentType] ?? []).concat(curr),
      };
    },
    {}
  );
  // eslint-disable-next-line no-restricted-syntax
  for (const [type, adjustments] of Object.entries(initialBuysideAdjustments)) {
    dispatch(updateBuySideAdjustmentsAction(adjustments, type));
  }
};

export const getLoanCatcherAdjustments = (loanInformation) => {
  return api.getAdjustments({
    loanNumber: loanInformation?.loanId,
  });
};

export const deleteAdjustments = (loanInformation, adjustments) => {
  return api.deleteAdjustments({
    adjustments,
    loanNumber: loanInformation?.loanNumber,
  });
};

export const validateRateDocInstance = async (rateDocInstanceID) => {
  let result;
  if (Session.get(IS_LO)) {
    const patToken = await Common.getPATToken();
    const originId = await Common.getOriginId();
    result = await api.getRateDocInstanceV1(
      { patToken, originId },
      rateDocInstanceID
    );
  } else {
    const jwtToken = await Session.get(JWT_TOKEN);
    result = await api.getRateDocInstanceV2({ jwtToken }, rateDocInstanceID);
  }
  const jsonData = await result.json();
  return result.ok && jsonData.status === 1;
};

export const getRateSheetDocument = async (rateDocInstanceID) => {
  let result;
  if (Session.get(IS_LO)) {
    const patToken = await Common.getPATToken();
    const originId = await Common.getOriginId();
    result = await api.getRateSheetDocumentV1(
      { patToken, originId },
      rateDocInstanceID
    );
  } else {
    const jwtToken = await Session.get(JWT_TOKEN);
    result = await api.getRateSheetDocumentV2({ jwtToken }, rateDocInstanceID);
  }
  if (result.ok) {
    const blob = await result.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "ratesheet");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }
  return result;
};

export const getEncompassSettings = async () => {
  let result = null;
  if (Session.get(IS_LO)) {
    result = await api.getEncompassSettingsV1();
  }
  return result;
};
