import { useOriginalLoanInformationSelector } from "data/useSelectors";
import Session, { ORIGIN_SOURCE } from "services/Session";
import { isEmpty } from "utils/shared";

const epcLoanActionMap = {
  NewLock: "TpoLockRequest",
  ActiveNewLock: "TpoLockRequest",
  ActiveLockUpdate: "TpoRePrice",
  ExpiredReLock: "TpoReLock",
  CancelledReLock: "TpoReLock",
};

export const getTPOActionAccessibility = (
  allowedActions,
  requestActionType
) => {
  const defaultValue = "ENABLED";
  let filterItem = [];
  if (allowedActions) {
    filterItem = allowedActions.filter(
      (item) => item.action === requestActionType
    );
  }
  return filterItem ? filterItem[0]?.accessibility : defaultValue;
};

export const getPersonaActionAccessibility = (
  allowedActions,
  requestActionType
) => {
  const defaultValue = "ENABLE";
  let filterItem = [];
  if (allowedActions) {
    filterItem = allowedActions.filter(
      (item) => item.loanAction === requestActionType
    );
  }
  return filterItem ? filterItem[0]?.restrictionType : defaultValue;
};

export const isRequestLockButtonVisible = (
  applicationName,
  loanData,
  allowedActions
) => {
  let isVisible = true;
  if (
    applicationName &&
    applicationName === "TPO" &&
    getTPOActionAccessibility(allowedActions, "TpoLockRequest") === "HIDDEN"
  ) {
    isVisible = false;
  }

  const requestLockActionType = getRequestLockActionType(loanData);
  /**
   * unfortunately the request lock button is used for multiple operations
   * so based on the request lock action type we need to determine if the permission applies to lock action type
   * TODO check in with product for better messaging
   */
  if (requestLockActionType) {
    const epcActionType = epcLoanActionMap[requestLockActionType];
    if (
      epcActionType &&
      getPersonaActionAccessibility(allowedActions, epcActionType) === "Hide"
    ) {
      isVisible = false;
    }
  }

  return isVisible;
};

export const isSaveToLoanButtonVisible = (
  applicationName,
  allowedActions,
  tpo,
  clientSettingsData
) => {
  const hideLockRequest = clientSettingsData?.hideLockRequest;
  if (hideLockRequest) {
    return false;
  }

  if (applicationName && applicationName === "TPO") {
    const isHidden =
      getTPOActionAccessibility(allowedActions, "FloatLock") === "HIDDEN";
    if (isHidden || tpo?.underwritingDelegated || hideLockRequest) return false;
  }
  return true;
};

export const isRequestLockButtonDisabled = (
  rateStale,
  loanData,
  applicationName,
  allowedActions,
  isDisableRequestLockButton,
  shutDownAllowLockRequestValidation,
  disableLock
) => {
  if (disableLock) {
    return true;
  }

  const { requestLockStatus, requestAction } = loanData;
  const isExpiredPricing = rateStale > 0; // 1 or 999
  const isLoanOfficer = Session.get(ORIGIN_SOURCE) !== "secondarylock";
  const isAllowLockRequest = shutDownAllowLockRequestValidation();
  if (
    applicationName &&
    applicationName === "TPO" &&
    getTPOActionAccessibility(allowedActions, "LockRequest") === "DISABLED"
  ) {
    return true;
  }

  if (
    getPersonaActionAccessibility(allowedActions, "LockRequest") === "DISABLE"
  ) {
    return true;
  }

  /**
   * unfortunately the request lock button is used for multiple operations
   * so based on the request lock action type we need to determine if the permission applies to lock action type
   * TODO check in with product for better messaging
   */

  const requestLockActionType = getRequestLockActionType(loanData);
  const epcActionType = requestLockActionType
    ? epcLoanActionMap[requestLockActionType]
    : null;
  const isPersonaDisabled =
    epcActionType &&
    getPersonaActionAccessibility(allowedActions, epcActionType) === "Disable";

  const isDisabled =
    (requestLockStatus === 0 && requestAction === 2) ||
    (requestLockStatus === 1 && requestAction === 2) ||
    (requestLockStatus === 3 && requestAction === 1) ||
    (requestLockStatus === 4 && requestAction === 1) ||
    (isLoanOfficer && isExpiredPricing) ||
    isDisableRequestLockButton ||
    isAllowLockRequest ||
    isPersonaDisabled;

  return isDisabled;
};

export const isFloatButtonVisible = (allowedActions, clientSettingsData) => {
  let isFloatBtnVisible = clientSettingsData && clientSettingsData.icePpeFloat;

  if (
    getPersonaActionAccessibility(allowedActions, "TpoFloatLock") === "Hide"
  ) {
    isFloatBtnVisible = false;
  }
  return isFloatBtnVisible;
};

export const isFloatButtonDisabled = (allowedActions) => {
  const { lockRequests } = useOriginalLoanInformationSelector();

  const lockRequestIsDenied = lockRequests?.every(
    (req) => req.requestedStatus === "Denied"
  );

  let isDisabled = lockRequests?.length && !lockRequestIsDenied;

  if (
    getPersonaActionAccessibility(allowedActions, "TpoFloatLock") === "Disable"
  ) {
    isDisabled = true;
  }

  return isDisabled;
};

export const isSaveToLoanButtonDisabled = (
  loanData,
  applicationName,
  allowedActions,
  disableLock
) => {
  if (disableLock) {
    return true;
  }
  if (applicationName && applicationName === "TPO") {
    const isDisabled =
      getTPOActionAccessibility(allowedActions, "FloatLock") === "DISABLED";
    if (isDisabled) return true;
    const { requestLockStatus, requestAction } = loanData;
    return requestAction === 2 && [3, 4].includes(requestLockStatus);
  }
  return false;
};

export const getRequestLockActionType = (loanData) => {
  let type = null;
  if (!isEmpty(loanData)) {
    const { requestLockStatus, requestAction } = loanData;
    if (requestLockStatus === 0 || requestLockStatus === 1) {
      if (requestAction === 1) type = "NewLock";
    }
    if (requestLockStatus === 2) {
      if (requestAction === 1) type = "ActiveNewLock";
      if (requestAction === 2) type = "ActiveLockUpdate";
    }
    if (requestLockStatus === 3) {
      if (requestAction === 2) type = "ExpiredReLock";
    }
    if (requestLockStatus === 4) {
      if (requestAction === 2) type = "CancelledReLock";
    }
  }
  return type;
};

export const getEPCActionType = (requestLockActionType, autolocked) => {
  const { buyside, sellSideBestPrice, sellSideSameProduct } = autolocked;
  let EPCActionType;
  if (
    requestLockActionType === "NewLock" ||
    requestLockActionType === "ActiveNewLock"
  ) {
    EPCActionType = "LOCK";
  } else {
    EPCActionType = "RELOCK";
  }
  if (buyside || sellSideBestPrice || sellSideSameProduct) {
    EPCActionType = "LOCK_CONFIRM";
  }
  return EPCActionType;
};

export const checkLockRequested = (loanData, lockRequests) => {
  const { requestAction, requestLockStatus } = loanData;
  const hasLockRequest = lockRequests?.some(
    (request) => request.requestedStatus === "Requested"
  );
  return hasLockRequest && requestAction === 1 && requestLockStatus === 1;
};
