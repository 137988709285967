import { BUTTON_VARIANT } from "@elliemae/ds-button";
import { Grid } from "@elliemae/ds-grid";
import { DSSeparatorV2 } from "@elliemae/ds-separator";
import FooterButtons from "components/FooterButtons";
import { closeLoader } from "data/screenLoader/actions";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as lockExtensionService from "services/lockExtensionServices";
import {
  StyledEppsContainerBody,
  StyledEppsParentView,
  StyledEppsWrapper,
} from "view/global/styles/styled";
import { lockExtentionAction } from "data/lockExtension/actionCreators";
import { openGlobalErrorModalAction } from "data/globalErrorModal/actionCreators";
import Common from "common/services/Common";
import AdjustmentTableSectionWrapper from "./adjustmentTableSectionWrapper";
import CurrentLockDetails from "./currentLockDetails";
import CalculatedExtensionDetails from "./calculatedExtensionDetails";
import ExtensionForm from "./extensionForm";
import LoanFieldValidationModal from "./loanFieldValidationModal";

const getLockData = ({ originalLoanInformation, extensionData, loanInfo }) => {
  const response = {
    rateSheetID: originalLoanInformation?.rateLock?.currentRateSheetId,
    lockValidationStatus:
      originalLoanInformation?.rateLock?.lockValidationStatus,
    lockDate: extensionData?.buySideLockDate,
    totalRateRequested: loanInfo?.loanInformation?.target,
    rateAdjustments: (extensionData?.currentAdjustments ?? [])
      .filter((ad) => ad.priceAdjustmentType === "BaseRate")
      .map((ad) => ({
        description: ad.description,
        amount: ad.rate,
      })),
    priceAdjustments: (extensionData?.currentAdjustments ?? [])
      .filter((ad) => ad.priceAdjustmentType === "BasePrice")
      .map((ad) => ({
        description: ad.description,
        amount: ad.rate,
      })),
    totalPriceRequested: extensionData?.currentPriceRateRequested,
    baseRate: loanInfo?.loanInformation?.target,
    basePrice: extensionData?.profitMarginAdjustedBuyPrice,
  };

  const allLockRequests = (originalLoanInformation?.lockRequests ?? []).sort(
    (a, b) =>
      new Date(a.requestedDate).getTime() - new Date(b.requestedDate).getTime()
  );
  const originalLockRequest = allLockRequests.toReversed().find(
    (lockRequest) =>
      // The most recent active lock. Not an extension. Could be original lock or a relock request.
      !lockRequest?.cumulatedDaystoExtend && !lockRequest?.buySideNumDayExtended
  );

  const currentLockRequest =
    allLockRequests?.length > 1
      ? allLockRequests?.find((i) => i.requestedStatus === "Locked")
      : null;

  const allLockExtensions = allLockRequests
    .slice(allLockRequests.indexOf(originalLockRequest))
    .filter((lockRequest) => lockRequest.cumulatedDaystoExtend);

  response.allLockRequests = [originalLockRequest, ...allLockExtensions];

  const originalLockExpirationDate =
    originalLockRequest?.snapshotFields?.find(
      ({ fieldId }) => fieldId === "2091"
    )?.value ?? originalLoanInformation?.rateLock?.buySideLockExpires;

  response.lockExpirationDate =
    extensionData?.buySideExtendedLockExpires ?? originalLockExpirationDate;

  response.originalLockRequest = {
    id: originalLockRequest?.id,
    requestedDate: originalLockRequest?.snapshotFields?.find(
      ({ fieldId }) => fieldId === "2149"
    )?.value,
    fulfilledDate: originalLockRequest?.snapshotFields?.find(
      ({ fieldId }) => fieldId === "2592"
    )?.value,
    numberOfDays:
      originalLoanInformation?.rateLock?.buySideNumberOfDays ||
      originalLoanInformation?.rateLock?.requestNumberOfDays,
    expiresDate: originalLockExpirationDate,
    cumulatedDaystoExtend: null,
  };

  response.currentLockRequest = !currentLockRequest
    ? null
    : {
        id: currentLockRequest?.id,
        requestedDate: currentLockRequest?.snapshotFields?.find(
          ({ fieldId }) => fieldId === "2149"
        )?.value,
        fulfilledDate: null,
        numberOfDays: currentLockRequest?.buySideNumDayExtended,
        expiresDate:
          originalLoanInformation?.rateLock?.buySideExtendedLockExpires,
        cumulatedDaystoExtend: currentLockRequest?.cumulatedDaystoExtend,
      };

  return response;
};

const canCalculateExtension = ({
  numberOfDays,
  parentLockUuid,
  extensionFormOptions,
}) =>
  Boolean(
    numberOfDays &&
      parentLockUuid &&
      numberOfDays <= extensionFormOptions.maxNumberOfDays
  );

const getParentLockUuid = (lockDetails) =>
  lockDetails?.currentLockRequest?.id ?? lockDetails?.originalLockRequest?.id;

const LockExtension = () => {
  const dispatch = useDispatch();
  const originalLoanInformation = useSelector(
    (state) => state?.EPPS?.originalLoanInformation
  );
  const extensionData = useSelector((state) => state?.EPPS?.extensionData);
  const loanInfo = useSelector((state) => state?.EPPS?.loanInfo);
  const [extensionLockDays, setExtensionLockDays] = useState(0);
  const [adjustmentOverride, setAdjustmentOverride] = useState(0);
  const [comments, setComments] = useState("");
  const [extendRequest, setExtendRequest] = useState(null);
  const lockDetails = useMemo(
    () => getLockData({ originalLoanInformation, extensionData, loanInfo }),
    [originalLoanInformation, extensionData, loanInfo]
  );

  useEffect(() => {
    if (
      extendRequest &&
      extensionLockDays <= extensionFormOptions?.maxNumberOfDays
    ) {
      // setComments runs asyncronously, and needs to wait for state to update to update
      // is there a better way to assign a click handler, that references useState data, that may not be updated yet?
      dispatch(
        lockExtentionAction({
          comments,
          daysToExtend: extensionLockDays,
          lockExtendPriceAdjustment:
            lockDateCalculation.priceAdjustment ||
            -Math.abs(adjustmentOverride),
        })
      );
    }
  }, [extendRequest]);

  const [extensionFormOptions, setExtensionFormOptions] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      if (!lockDetails?.allLockRequests?.length) {
        return;
      }

      const {
        getControlSettings,
      } = await lockExtensionService.getLockPageSettings();

      const settings = getControlSettings({
        lockRequests: lockDetails?.allLockRequests ?? [],
        originalLockRequest: lockDetails?.originalLockRequest,
        currentLockRequest: lockDetails?.currentLockRequest,
      });

      setExtensionFormOptions(settings);
    };

    fetchSettings();
  }, [lockDetails]);

  const [lockDateCalculation, setLockDateCalculation] = useState({
    lockExpirationDate: "",
    numberOfDays: 0,
    priceAdjustment: 0,
  });

  const [pendingCalculationRequests] = useState([]);

  useEffect(() => {
    setLockDateCalculation({});
    pendingCalculationRequests.splice(0, pendingCalculationRequests.length);

    const numberOfDays = Number(extensionLockDays);
    const parentLockUuid = getParentLockUuid(lockDetails);

    if (
      canCalculateExtension({
        numberOfDays,
        parentLockUuid,
        extensionFormOptions,
      })
    ) {
      const calculationRequest = lockExtensionService.calculateRateLockExtension(
        loanInfo.customId,
        {
          numberOfDays,
          lockDate: lockDetails?.lockExpirationDate,
          parentLockUuid,
        }
      );

      pendingCalculationRequests.push(calculationRequest);

      calculationRequest.then((response) => {
        if (pendingCalculationRequests.includes(calculationRequest)) {
          setLockDateCalculation({
            ...response,
            priceAdjustment:
              -Math.abs(adjustmentOverride) || response.priceAdjustment,
          });
        }
      });
    }
  }, [
    lockDetails,
    extensionLockDays,
    adjustmentOverride,
    extensionFormOptions,
  ]);

  useEffect(() => {
    const showError = ({ title, errorMessage }) =>
      dispatch(
        openGlobalErrorModalAction({
          onCloseCallback: () => Common.closeApplication(),
          title,
          errorMessage,
          confirmLabel: "Exit ICE PPE",
        })
      );

    const totalExtensions = lockDetails?.allLockRequests?.length;
    const hasRequestedRequests = lockDetails?.allLockRequests?.some(
      (i) => i?.requestedStatus === "Requested"
    );
    if (
      extensionFormOptions?.totalExtensionsAllowed &&
      totalExtensions &&
      totalExtensions > extensionFormOptions.totalExtensionsAllowed
    ) {
      showError({
        title: "Lock Extension Limit Reached",
        errorMessage: `Loan cannot be extended. Loan ${
          loanInfo.loanInformation.loanNumber ?? loanInfo.loanInformation.loanId
        } has already been extended its max number of times (${
          extensionFormOptions.totalExtensionsAllowed
        }).`,
      });
      return;
    }

    if (hasRequestedRequests) {
      showError({
        title: "Pending Pricing Request",
        errorMessage: `Loan ${
          loanInfo.loanInformation.loanNumber ?? loanInfo.loanInformation.loanId
        } currently has a pending pricing request. Retry after request has been completed.`,
      });
      return;
    }

    if (typeof extensionFormOptions?.maxNumberOfDays === "number") {
      const noEnumeratedOptions =
        extensionFormOptions?.daysToExtendControlType === "Enumerated" &&
        !extensionFormOptions.daysToExtendControlOptions.length;
      const noTextOptions =
        extensionFormOptions?.daysToExtendControlType === "Text" &&
        !extensionFormOptions.maxNumberOfDays;
      if (noEnumeratedOptions || noTextOptions) {
        showError({
          title: "Max Extension Reached",
          errorMessage: `Loan ${
            loanInfo.loanInformation.loanNumber ??
            loanInfo.loanInformation.loanId
          } has reached the maximum number of extension days`,
        });
      }
    }
  }, [extensionFormOptions, lockDetails]);

  const canExtendLock = useMemo(() => {
    const response =
      Boolean(
        lockDateCalculation?.lockExpirationDate &&
          lockDateCalculation?.numberOfDays &&
          typeof lockDateCalculation?.priceAdjustment === "number"
      ) &&
      canCalculateExtension({
        numberOfDays: Number(extensionLockDays),
        parentLockUuid: getParentLockUuid(lockDetails),
        extensionFormOptions,
      });

    return response;
  }, [
    lockDateCalculation,
    extensionLockDays,
    extensionFormOptions,
    lockDetails,
  ]);

  const footerButtons = useMemo(
    () => [
      {
        id: "cancel",
        type: "secondary",
        text: "Cancel",
        variant: BUTTON_VARIANT.DEFAULT,
        disabled: false,
        onClick: () => Common.closeApplication(),
      },
      {
        id: "request-extension",
        type: "primary",
        text: "Request Extension",
        variant: BUTTON_VARIANT.DEFAULT,
        disabled: !canExtendLock,
        size: "m",
        onClick: (val) => setExtendRequest(val),
      },
    ],
    [canExtendLock]
  );

  useEffect(() => {
    dispatch(closeLoader());
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledEppsParentView>
      <StyledEppsContainerBody>
        <StyledEppsWrapper>
          <LoanFieldValidationModal
            lockValidationStatus={lockDetails.lockValidationStatus}
          />
          <Grid>
            <CurrentLockDetails lockDetails={lockDetails} />
          </Grid>

          <DSSeparatorV2 m="m 0" isDotted />

          <Grid>
            <AdjustmentTableSectionWrapper lockDetails={lockDetails} />
          </Grid>

          <DSSeparatorV2 m="m 0" />

          <h3>Extension Information</h3>

          <Grid>
            <Grid
              cols={{
                small: [1 / 1, 0, 1 / 1],
                medium: ["375px", "auto", "1fr"],
              }}
            >
              <Grid>
                <CalculatedExtensionDetails
                  lockDetails={lockDetails}
                  lockDateCalculation={lockDateCalculation}
                />
              </Grid>
              <DSSeparatorV2 m="0 s" isDotted isVertical />

              <Grid>
                {extensionFormOptions && (
                  <ExtensionForm
                    controlOptions={extensionFormOptions}
                    extensionLockDays={extensionLockDays}
                    setExtensionLockDays={setExtensionLockDays}
                    comments={comments}
                    setComments={setComments}
                    adjustmentOverride={adjustmentOverride}
                    setAdjustmentOverride={setAdjustmentOverride}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid>
            <FooterButtons buttons={footerButtons} />
          </Grid>
        </StyledEppsWrapper>
      </StyledEppsContainerBody>
    </StyledEppsParentView>
  );
};

export default LockExtension;
