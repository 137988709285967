import Common from "common/services/Common";
import { get } from "lodash";
import { convertLoanInfoToEPCModel } from "./helper";
import { setPersonaAccessData } from "./actions";

export const getLoanAccessRules = async (loanId) => {
  const response = await Common.evaluateLoanAccessRules(loanId);
  return response;
};

export const evaluateLoanAccessRules = async (loanId, loan) => {
  const response = await Common.evaluateLoanAccessRules(
    loanId,
    undefined,
    loan
  );
  return response;
};

export const fetchCurrentPersonaAccessRules = () => async (dispatch, state) => {
  try {
    const loanData = get(state(), "EPPS.originalLoanInformation") || {};
    const modifiedLoanData = get(state(), "EPPS.loanInfo") || {};
    const emptyLoanData = convertLoanInfoToEPCModel(modifiedLoanData);
    // I need the guid which seems to be coming from customId
    const results = await evaluateLoanAccessRules(loanData.id, emptyLoanData);
    if (results !== undefined) {
      if (results.personaAccessToLoanActionRules !== undefined) {
        const personaAccessSettings = results.personaAccessToLoanActionRules;
        dispatch(setPersonaAccessData(personaAccessSettings));
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
