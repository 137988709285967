import React, { useEffect, useState, useRef } from "react";
import { isEmpty } from "utils/shared";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { DSModal } from "@elliemae/ds-modal";
import { DSTabs, DSTab } from "@elliemae/ds-tabs";
import AdjustmentDetailsView from "view/AdjustmentDetails/AdjustmentDetailsView";
import {
  useUserData,
  useLoanInformationField,
  useLockedProgramSelector,
  usePropertyDetailsCache,
  useOriginalLoanInformationSelector,
} from "data/useSelectors";

import { fetchCurrentPersonaAccessRules } from "data/personaAccess/actionCreators";
import { PATH_SEARCH_FOR_PRODUCTS } from "routes/constants";
import {
  StyledEppsParentView,
  StyledEppsContainerBody,
  StyledEppsWrapper,
} from "view/global/styles/styled";
import { calcDeltas } from "utils/calculateTarget";
import { DataProvider } from "utils/customHooks/useAppData";
import Session, {
  IS_BUYSIDE,
  APPLICATION_NAME,
  USER_TYPE,
} from "services/Session";
import { lookupsActions } from "store/lookups";
import Rights from "common/services/Rights";
import {
  UPDATE_ADJUSTMENT_DETAILS_CACHE,
  UPDATE_LOANINFO,
  UPDATE_PROPERTY_DETAILS_CACHE,
} from "common/constants/ActionConstants";
import createAction from "utils/createAction";
import { getLoanLimits } from "services/searchFormServices";
import {
  getLoanCatcherAdjustments,
  processLoancatcherAdjustments,
} from "services/lockSummaryServices";
import { setLoanLimits } from "data/lockSummary/actions";
import { closeLoader } from "data/screenLoader/actions";
import { calculateGovtUpfrontAmount } from "app/helpers";
import { useLookupOptions } from "components/Form/utils/customHooks";
import QuickSearch from "./components/QuickSearch";
import BestExGrid from "./components/BestExGrid";
import { setRedirectTabAction } from "../../data/landing/actions";
import { loanData as defaultLoanData } from "../../common/constants/SearchSampleData";
import AllRatesView from "./components/AllRatesView/index";
import {
  getRates,
  getWebhookRates,
  setAllRatesRowData,
} from "../../Qualification/QualificationActions";
import { openGlobalErrorModalAction } from "../../data/globalErrorModal/actionCreators";
import { getTarget, applyFilter } from "./helpers";
import IneligiblePrograms from "./components/IneligiblePrograms";
import ClassicGrid from "./components/ClassicGrid";
import IneligibleUnavailableModal from "./components/IneligibleUnavailableModal";

const loanDataSelector = ({ EPPS: { loanInfo = defaultLoanData } }) => loanInfo;
const qualificationResultSelector = ({ EPPS }) =>
  (EPPS && EPPS.loanPrograms) || {};
const loanLimitsSelector = ({
  epps: {
    lockSummary: { loanLimits = [] },
  },
}) => loanLimits;

const SearchResult = () => {
  const dispatch = useDispatch();
  const targetValue = useLoanInformationField("target");
  const qualificationResult = useSelector(qualificationResultSelector);
  const loanLimitsResult = useSelector(loanLimitsSelector);
  const loanData = useSelector(loanDataSelector);
  const { requestAction, requestLockStatus } = loanData;
  const relockFlag = requestAction === 2 && requestLockStatus === 2;
  const allRatesRowData = useSelector((state) => state.EPPS.allRatesRowData);
  const action = createAction(UPDATE_LOANINFO);
  const tpoPricingGroup = useSelector(
    ({ personaAccess }) => personaAccess.pricingGroup
  );
  const [loanPrograms, setLoanPrograms] = useState([]);
  const [filters] = useState({});
  const [target, setTarget] = useState(getTarget(loanData));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshPrograms, setRefreshPrograms] = useState(false);
  const [data, setData] = useState({});
  const [user] = useState(Session.get(USER_TYPE));
  const [activeTab, setActiveTab] = useState("Classic");
  const refEligible = useRef("");
  const refIneligible = useRef("");
  const refBestExecution = useRef("");
  const [loanLimitsData, setloanLimitsData] = useState([]);
  const [eligibleProducts, setEligibleProducts] = useState(0);
  const [ineligibleProducts, setIneligibleProducts] = useState(0);
  const [bestExecution, setBestExecution] = useState(0);
  const { focusIneligible, status } = useLockedProgramSelector();
  const propertyDetailsCache = usePropertyDetailsCache() || [];
  const govtUpfrontFees = useLookupOptions("fee") || [];
  let ltv = useOriginalLoanInformationSelector("ltv");
  const originalLoanInformation = useOriginalLoanInformationSelector();
  const buysideAdjustments = useSelector(({ epps }) =>
    Object.values(epps.origin.buySideAdjustments ?? {}).flat(1)
  );

  const isBuySide = Session.get(IS_BUYSIDE);

  const getBestExRows = !isEmpty(qualificationResult.productGroups)
    ? qualificationResult.productGroups.map((row) => ({ ...row, parent: true }))
    : [];
  let { defaultUserView = "BestEx" } = useUserData();
  const tabsToRender = useRef([]);

  const updateAdjustmentDetailsCache = createAction(
    UPDATE_ADJUSTMENT_DETAILS_CACHE
  );
  const updatePropertyDetailsCache = createAction(
    UPDATE_PROPERTY_DETAILS_CACHE
  );

  useEffect(() => {
    if (isEmpty(tabsToRender.current)) {
      if (Rights.accessStandardView) {
        tabsToRender.current.push({
          tabId: "Classic",
          key: uuidv4(),
          title: "Standard",
        });
      }
      if (Rights.accessBestexView) {
        tabsToRender.current.push({
          tabId: "BestEx",
          key: uuidv4(),
          title: "BestEx",
          style: relockFlag ? { display: "none" } : {},
        });
      }
      tabsToRender.current.splice(1, 0, {
        tabId: "Ineligible",
        key: uuidv4(),
        title: "Ineligible Programs",
        style: relockFlag ? { display: "none" } : {},
      });
      if (!Rights.accessBestexView || relockFlag) {
        defaultUserView = "Classic";
      }
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setActiveTab(defaultUserView);
  }, [defaultUserView]);

  useEffect(() => {
    setloanLimitsData(loanLimitsResult);
  }, [loanLimitsResult]);

  useEffect(() => {
    setTarget(getTarget(loanData));
  }, [loanData]);

  useEffect(() => {
    if (focusIneligible) {
      setActiveTab("Ineligible");
    }
  }, [focusIneligible]);

  // calculate deltas
  const handleCalculateDeltas = () => {
    const calculateTarget = getTarget(loanData);
    const { loanPrograms: programs } = qualificationResult;
    const cloneLoanPrograms = [...programs];
    const getDeltas = calcDeltas(cloneLoanPrograms, calculateTarget);
    setLoanPrograms(getDeltas);
  };

  useEffect(() => {
    const { loanPrograms: programs } = qualificationResult;
    if (programs) {
      handleCalculateDeltas();
    }
  }, [qualificationResult, loanData, targetValue]);

  const qualifyPrograms = async (newLoanData, isSearchPage = true) => {
    setRefreshPrograms(true);
    const loan = newLoanData || loanData;
    const isHeloc = !!(loan.loanType === "HELOC");
    const applicationName = Session.get(APPLICATION_NAME);
    if (isHeloc) {
      loan.loanType = "HELOC";
      if (loan.loanInformation.lienPosition === 1) {
        loan.loanInformation.helocInitialDraw =
          loan.loanInformation.firstMortgageAmount;
      } else if (loan.loanInformation.lienPosition === 2) {
        loan.loanInformation.helocInitialDraw =
          loan.loanInformation.secondMortgageAmount;
      }
    } else {
      loan.loanType = "Conventional";
      loan.loanInformation.helocInitialDraw = null;
    }
    if (applicationName === "TPO") {
      loan.facadeUserID = tpoPricingGroup;
    }
    // only calculate fee amount if not called from search page
    if (!isSearchPage) {
      const loanLtv = loan?.loanInformation?.ltv;
      if (loanLtv) {
        ltv = loanLtv;
      }
      const {
        feeAmount,
        feeAmountFinanced,
        feeAmountPaidinCash,
      } = calculateGovtUpfrontAmount(
        loan,
        originalLoanInformation,
        govtUpfrontFees,
        ltv
      );
      if (feeAmount) {
        loanData.loanInformation.financedAmount = 0;
        loanData.loanInformation.feeAmount = feeAmount || 0;
        loanData.loanInformation.feeAmountFinanced = feeAmountFinanced || 0;
        loanData.loanInformation.feeAmountPaidinCash = feeAmountPaidinCash || 0;
      }
    }

    const copyLoan = cloneDeep(loan);
    if (isBuySide) {
      copyLoan.relockRequest.specificProgramID = null;
      copyLoan.relockRequest.specificRateDataID = null;
    }
    if (user && user.userType === "StandAlone") {
      await dispatch(getRates(copyLoan));
    } else {
      await dispatch(getWebhookRates(copyLoan));

      if (
        propertyDetailsCache?.state !== copyLoan?.property?.state ||
        propertyDetailsCache?.county !== copyLoan?.property?.county ||
        propertyDetailsCache?.zip !== copyLoan?.property?.zip
      ) {
        // Property details have changed, query loan limits again
        const updatedPropertyDetailsCache = {
          state: loanData.property.state,
          county: loanData.property.county,
          zip: loanData.property.zip,
        };
        dispatch(updatePropertyDetailsCache(updatedPropertyDetailsCache));
        getLoanLimits(loanData).then((res) => {
          dispatch(setLoanLimits(res));
        });
      }
    }
    dispatch(updateAdjustmentDetailsCache([]));

    const loanCatcherAdjustments = await getLoanCatcherAdjustments(
      loan.loanInformation
    );
    if (loanCatcherAdjustments?.length > 0) {
      processLoancatcherAdjustments(
        dispatch,
        loan,
        buysideAdjustments,
        loanCatcherAdjustments
      );
    }
    if (newLoanData) dispatch(action(copyLoan));
    dispatch(fetchCurrentPersonaAccessRules());
    setRefreshPrograms(false);
  };

  useEffect(() => {
    if (allRatesRowData) {
      const refreshedProgram = qualificationResult.loanPrograms.find(
        ({ programID }) => programID === allRatesRowData.programID
      );
      dispatch(setAllRatesRowData(refreshedProgram));
    }
  }, [qualificationResult.loanPrograms]);

  useEffect(() => {
    const getData = async () => {
      const { loanPurpose } = loanData.loanInformation;
      if (loanPurpose === 99) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: "Loan Purpose of Other is not supported.",
            onCloseCallback: () => {
              dispatch(setRedirectTabAction(PATH_SEARCH_FOR_PRODUCTS));
              dispatch(lookupsActions.getLookupsDataAction({ dispatch }));
            },
          })
        );
      } else {
        await qualifyPrograms();
        await dispatch(lookupsActions.getLookupsDataAction({ dispatch }));
        dispatch(closeLoader());
      }
    };
    getData();
    setloanLimitsData(loanLimitsResult);
  }, []);

  useEffect(() => {
    setTarget(getTarget(loanData));
  }, [loanData]);

  const showDetailsModal = (e, idLoan, programId, rateDataId, lienPosition) => {
    e.preventDefault();
    setData({
      loanId: idLoan,
      lienPosition,
      rateDataId,
      programId,
    });
    setIsModalOpen(!isModalOpen);
  };

  const ProgramDetailsModal = () => (
    <DSModal
      isOpen={isModalOpen}
      modalTitle="Program Details"
      onClose={(e) => showDetailsModal(e, null, null)}
      showFooter={false}
    >
      <p>
        <AdjustmentDetailsView data={data} />
      </p>
    </DSModal>
  );

  if (allRatesRowData) {
    return (
      <AllRatesView
        allRatesData={allRatesRowData}
        loanData={loanData}
        qualifyPrograms={qualifyPrograms}
      />
    );
  }

  return (
    <StyledEppsParentView overflow="hidden">
      <StyledEppsContainerBody className="search-result">
        <StyledEppsWrapper paddingBottom="0">
          {!isBuySide && (
            <div style={{ paddingTop: "16px" }}>
              <QuickSearch onSearch={qualifyPrograms} />
            </div>
          )}
          {isBuySide && loanPrograms.length !== 0 && status !== "eligible" && (
            <IneligibleUnavailableModal />
          )}
          <DataProvider
            value={{
              refEligible,
              refIneligible,
              refBestExecution,
              setEligibleProducts,
              setIneligibleProducts,
              setBestExecution,
            }}
          >
            {!isEmpty(Rights) && !isEmpty(tabsToRender.current) && (
              <DSTabs
                animated={false}
                height="100%"
                activeTab={activeTab}
                onTabChange={setActiveTab}
              >
                {tabsToRender.current.map(({ tabId, key, title, style }) => {
                  const counters = {
                    Classic: eligibleProducts,
                    Ineligible: ineligibleProducts,
                    BestEx: bestExecution,
                  };
                  let componentToRender = null;
                  if (tabId === "Classic") {
                    componentToRender = () => (
                      <ClassicGrid
                        loanPrograms={loanPrograms}
                        refreshPrograms={refreshPrograms}
                        onSearch={qualifyPrograms}
                        applyFilter={() => {
                          refEligible.current = false;
                          refIneligible.current = false;
                          refBestExecution.current = false;
                          return applyFilter(filters);
                        }}
                        updateAdjustmentDetailsCache={
                          updateAdjustmentDetailsCache
                        }
                      />
                    );
                  }
                  if (tabId === "BestEx") {
                    componentToRender = () => (
                      <BestExGrid
                        productGroups={getBestExRows}
                        loanPrograms={loanPrograms}
                        target={target}
                        ratesBestEx={qualificationResult.ratesBestEx}
                        lockDaysBestEx={qualificationResult.lockDays}
                        onSearch={qualifyPrograms}
                        updateAdjustmentDetailsCache={
                          updateAdjustmentDetailsCache
                        }
                      />
                    );
                  }
                  if (tabId === "Ineligible") {
                    componentToRender = () => (
                      <IneligiblePrograms
                        loanPrograms={loanPrograms}
                        loanLimits={loanLimitsData}
                        refreshPrograms={refreshPrograms}
                      />
                    );
                  }
                  return (
                    <DSTab
                      tabId={tabId}
                      title={`${title} (${counters[tabId]})`}
                      key={key}
                      style={style}
                    >
                      {componentToRender()}
                    </DSTab>
                  );
                })}
              </DSTabs>
            )}
          </DataProvider>
          <ProgramDetailsModal />
        </StyledEppsWrapper>
      </StyledEppsContainerBody>
    </StyledEppsParentView>
  );
};

export default SearchResult;
