import { SET_PERSONA_ACCESS_DATA } from "./actions";

const INITIAL_STATE = {
  allowedActions: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PERSONA_ACCESS_DATA:
      return {
        ...state,
        allowedActions: action.payload,
      };
    default:
      return state;
  }
};
