import {
  TRANS_ID_KEY,
  JWT_TOKEN_KEY,
  USER_TYPE_KEY,
  PAT_TOKEN_KEY,
  ORIGIN_ID_KEY,
  OLD_ORIGIN_ID_KEY,
  CRED_KEY,
  SUMMARY_PARAMS,
} from "common/constants/AppGlobals";

export const TRANS_ID = TRANS_ID_KEY;
export const CRED = CRED_KEY;
export const ALL_SUMMARY_PARAMS = SUMMARY_PARAMS;
export const USER_TYPE = USER_TYPE_KEY;
export const PAT_TOKEN = PAT_TOKEN_KEY;
export const JWT_TOKEN = JWT_TOKEN_KEY;
export const ORIGIN_ID = ORIGIN_ID_KEY;
export const OLD_ORIGIN_ID = OLD_ORIGIN_ID_KEY;
export const ORIGIN_SOURCE = "ORIGIN_SOURCE";
export const ORIGIN_PAT_TOKEN = "ORIGIN_PAT_TOKEN";
export const APPLICATION_NAME = "SOURCE_APPLICATION_NAME";
export const STANDALONE_JWT_TOKEN = "STANDALONE_JWT_TOKEN";
export const ORIGIN_LOCKID = "ORIGIN_LOCKID";
export const ORIGIN_TRANSACTION_TYPE = "ORIGIN_TRANSACTION_TYPE";
export const IS_STANDALONE = "IS_STANDALONE";
export const IS_JWT_TOKEN = "IS_JWT_TOKEN";
export const IS_LO = "IS_LO";
export const ORIGIN_IS_UPDATE = "ORIGIN_LOCK_UPDATE";
export const APPLICATION_TYPE = "APPLICATION_TYPE";
export const IS_BUYSIDE = "IS_BUYSIDE";
export const IS_SELLSIDE = "IS_SELLSIDE";
export const IS_VALIDATE = "IS_VALIDATE";
export const IS_LOCK_EXTENSION = "IS_LOCK_EXTENSION";
export const IS_SECONDARY_LOCK = "IS_SECONDARY_LOCK";
export const IS_LOCK_REQUEST_FORM_LOCK_EXTENSION =
  "IS_LOCK_REQUEST_FORM_LOCK_EXTENSION";

class Session {
  set(key, value) {
    const newValue = this.transformSetValue(key, value);
    if (typeof newValue !== "object") sessionStorage.setItem(key, newValue);
  }

  get(key) {
    let newValue = sessionStorage.getItem(key);
    newValue = this.transformGetValue(key, newValue);
    return newValue;
  }

  remove(key) {
    sessionStorage.removeItem(key);
  }

  transformSetValue(key, value) {
    let newValue = value;
    if (typeof key === "object" && value === undefined) {
      Object.keys(key).forEach((mapKey) => {
        this.set(mapKey, key[mapKey]);
      });
    }
    if ([ORIGIN_SOURCE, ORIGIN_TRANSACTION_TYPE, ORIGIN_LOCKID].includes(key)) {
      const arrayValues = value?.split(":") || [];
      newValue = this.findIndexValue(key, arrayValues);
    }
    if (key === APPLICATION_NAME) {
      newValue = newValue?.replace("-", "");
    }
    if ([USER_TYPE, CRED, ALL_SUMMARY_PARAMS].includes(key)) {
      newValue = JSON.stringify(newValue);
    }
    if ([ORIGIN_IS_UPDATE].includes(key)) {
      newValue =
        newValue?.some(
          ({ requestedStatus, id }) =>
            requestedStatus === "Requested" && id === this.get(ORIGIN_LOCKID)
        ) &&
        newValue?.some(({ requestedStatus }) => requestedStatus === "Locked");
    }
    return newValue;
  }

  transformGetValue(key, value) {
    let newValue = value;
    if ([CRED, USER_TYPE, ALL_SUMMARY_PARAMS, ORIGIN_IS_UPDATE].includes(key)) {
      newValue = JSON.parse(newValue);
    }
    if (key === IS_STANDALONE) {
      newValue = this.get(USER_TYPE) === "StandAlone";
    }
    if (key === IS_JWT_TOKEN) {
      newValue = !!this.get(JWT_TOKEN);
    }
    if (key === IS_LO) {
      newValue = this.get(USER_TYPE)?.userType === "LO";
    }
    if (key === IS_BUYSIDE) {
      const type = this.get(ORIGIN_TRANSACTION_TYPE);
      newValue = ["getbuysidepricing", "validatelock"].includes(type);
    }
    if (key === IS_SELLSIDE) {
      const type = this.get(ORIGIN_TRANSACTION_TYPE);
      newValue = type === "getsellsidepricing";
    }
    if (key === IS_VALIDATE) {
      return this.get(ORIGIN_TRANSACTION_TYPE) === "validatelock";
    }
    if (key === IS_SECONDARY_LOCK) {
      return this.get(ORIGIN_SOURCE) === "secondarylock";
    }
    if (key === IS_LOCK_REQUEST_FORM_LOCK_EXTENSION) {
      return (
        this.get(ORIGIN_SOURCE) === "lockrequest" &&
        this.get(ORIGIN_TRANSACTION_TYPE) === "extendlock"
      );
    }

    if (key === IS_LOCK_EXTENSION) {
      return this.get(ORIGIN_TRANSACTION_TYPE) === "extendlock";
    }
    return newValue;
  }

  findIndexValue(key, array) {
    let index;
    if (key === ORIGIN_SOURCE) {
      index = 4;
    }
    if (key === ORIGIN_TRANSACTION_TYPE) {
      index = 5;
    }
    if (key === ORIGIN_LOCKID) {
      if (array[6]?.toLowerCase() === "lockid") return array[7];
      return "";
    }
    return array[index] || "";
  }
}
export default new Session();
